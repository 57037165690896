import React from 'react'

import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { Heading } from '../../../main/components/typography'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DELIMITER = 'x'
const DEFAULT_RANGE = { from: '', to: '' }

export default function FloatFilter({ config, onSubmit }: FilterProps) {
  const [sharesRange, setSharesRange] = React.useState(DEFAULT_RANGE)
  const [pctRange, setPctRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const parts = filter.split(DELIMITER)

      const [aFrom = '', aTo = ''] = parts[0].includes('to') ? parts[0].split('to') : []
      const [bFrom = '', bTo = ''] = parts[1]?.includes('to') ? parts[1].split('to') : []

      setSharesRange({ from: aFrom, to: aTo })
      setPctRange({ from: bFrom, to: bTo })
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([rangesToFilter([sharesRange, pctRange])])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <Heading level={5} className="mb-3 mt-0">
          Match any of the following:
        </Heading>
        <form id="js-floatFilterForm" onSubmit={handleSubmit}>
          <div className="grid grow grid-cols-2 gap-3">
            <Input
              label="From (M)"
              type="number"
              value={sharesRange.from}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.01}
              onChange={(e) => {
                setSharesRange({ ...sharesRange, from: e.currentTarget.value })
              }}
            />
            <Input
              label="To (M)"
              type="number"
              value={sharesRange.to}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.01}
              onChange={(e) => {
                setSharesRange({ ...sharesRange, to: e.currentTarget.value })
              }}
            />
          </div>

          <div className="mt-3 grid grow grid-cols-2 gap-3">
            <Input
              label="From (%)"
              type="number"
              value={pctRange.from}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.01}
              onChange={(e) => {
                setPctRange({ ...pctRange, from: e.currentTarget.value })
              }}
            />
            <Input
              label="To (%)"
              type="number"
              value={pctRange.to}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.01}
              onChange={(e) => {
                setPctRange({ ...pctRange, to: e.currentTarget.value })
              }}
            />
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-floatFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
