import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { ready } from '../shared/ready'
import { ScreenerPremium } from './screener-premium'
import { ScreenerTimeframeBar } from './screener-timeframe-bar'

const SCREENER_TIMEFRAME_REACT_ROOT_ID = 'screener-timeframe-container'

let timeframeRoot: ReactDOM.Root
const screenerTimeframeReactMount = () => {
  const timeframeContainer = document.getElementById(SCREENER_TIMEFRAME_REACT_ROOT_ID)
  if (timeframeContainer) {
    timeframeRoot = ReactDOM.createRoot(timeframeContainer)

    timeframeRoot.render(
      <BrowserRouter>
        <ScreenerTimeframeBar />
      </BrowserRouter>
    )
  }
}

const screenerTimeframeReactUnmount = () => {
  timeframeRoot?.unmount()
}

ready(() => {
  const screener = document.getElementById('screener-react')
  if (screener && FinvizSettings.hasUserPremium) {
    const root = ReactDOM.createRoot(screener)

    root.render(<ScreenerPremium />)
  }
  screenerTimeframeReactMount()
})

window.ScreenerTimeframeReactMount = screenerTimeframeReactMount
window.ScreenerTimeframeReactUnmount = screenerTimeframeReactUnmount
