import React from 'react'

import { Dialog, DialogHeader, DialogProps } from '../../../main/components/dialog'
import { decodeQueryString, encodeQueryString } from '../../queryString'
import { parseScreenerFilters, stringifyScreenerFilters } from '../utils'
import CurrentVolumeFilter from './currentvolume-filter'
import DateRangeFilter from './daterange-filter'
import DefaultFilter from './default-filter'
import EarningsAndRevenueFilter from './epsrev-filter'
import FloatFilter from './float-filter'
import HighLowFilter from './highlow-filter'
import IpoDateFilter from './ipodate-filter'
import OptionsFilter from './options-filter'
import PeriodRangeFilter from './period-range-filter'
import SMAFilter from './sma-filter'
import TargetPriceFilter from './targetprice-filter'
import { ConfigType } from './types'
import VolatilityFilter from './volatility-filter'

const PERFORMANCE_OPTIONS = [
  { value: 'i1', label: '1 Minute' },
  { value: 'i2', label: '2 Minutes' },
  { value: 'i3', label: '3 Minutes' },
  { value: 'i5', label: '5 Minutes' },
  { value: 'i10', label: '10 Minutes' },
  { value: 'i15', label: '15 Minutes' },
  { value: 'i30', label: '30 Minutes' },
  { value: 'h', label: '1 Hour' },
  { value: 'h2', label: '2 Hours' },
  { value: 'h4', label: '4 Hours' },
  { value: 'd', label: 'Day' },
  { value: '1w', label: 'Week' },
  { value: '4w', label: 'Month' },
  { value: '13w', label: 'Quarter' },
  { value: '26w', label: 'Half' },
  { value: '52w', label: 'Year' },
  { value: 'ytd', label: 'YTD' },
]

const NET_FLOWS_FILTER_OPTIONS = [
  { value: '1m', label: '1 Month' },
  { value: '3m', label: '3 Month' },
  { value: 'ytd', label: 'YTD' },
  { value: '1y', label: '1 Year' },
]

const RETURN_FILTER_OPTIONS = [
  { value: '1y', label: '1 Year' },
  { value: '3y', label: '3 Year' },
  { value: '5y', label: '5 Year' },
  { value: '10y', label: '10 Year' },
  { value: 'si', label: 'Since Inception' },
]

interface FilterDialogProps {
  config: ConfigType | null
  dialogState: DialogProps['state']
  dialogBodyClassName?: string
}

export function FilterDialog({ config, dialogState }: FilterDialogProps) {
  const handleSubmit = React.useCallback(
    (values: (string | null)[]) => {
      if (config) {
        const { filter } = config
        const filters = {
          ...parseScreenerFilters(),
          [filter]: values.filter((value) => !!value) as string[],
        }
        const urlQueryObject = decodeQueryString()
        window.location.href =
          'screener.ashx?' + encodeQueryString({ ...urlQueryObject, f: stringifyScreenerFilters(filters) })
      }
    },
    [config]
  )

  if (!config) return null

  const getDialogComponent = () => {
    switch (config.filter) {
      case 'ipodate':
        return <IpoDateFilter config={config} onSubmit={handleSubmit} />
      case 'earningsdate':
      case 'news_date':
        return <DateRangeFilter config={config} onSubmit={handleSubmit} />
      case 'ta_perf':
      case 'ta_perf2':
        return <PeriodRangeFilter periodOptions={PERFORMANCE_OPTIONS} config={config} onSubmit={handleSubmit} />
      case 'ta_volatility':
        return <VolatilityFilter config={config} onSubmit={handleSubmit} />
      case 'targetprice':
        return <TargetPriceFilter config={config} onSubmit={handleSubmit} />
      case 'ta_sma20':
      case 'ta_sma50':
      case 'ta_sma200':
        return <SMAFilter config={config} onSubmit={handleSubmit} />
      case 'ta_highlow20d':
      case 'ta_highlow50d':
      case 'ta_highlow52w':
      case 'ta_alltime':
        return <HighLowFilter config={config} onSubmit={handleSubmit} />
      case 'sh_float':
        return <FloatFilter config={config} onSubmit={handleSubmit} />
      case 'sh_curvol':
        return <CurrentVolumeFilter config={config} onSubmit={handleSubmit} />
      case 'etf_fundflows':
        return (
          <PeriodRangeFilter
            periodOptions={NET_FLOWS_FILTER_OPTIONS}
            config={config}
            onSubmit={handleSubmit}
            useOverUnderLabels
          />
        )
      case 'etf_return':
        return (
          <PeriodRangeFilter
            periodOptions={RETURN_FILTER_OPTIONS}
            config={config}
            onSubmit={handleSubmit}
            useOverUnderLabels
          />
        )
      case 'sh_opt':
        return <OptionsFilter config={config} onSubmit={handleSubmit} />
      case 'fa_epsrev':
        return <EarningsAndRevenueFilter config={config} onSubmit={handleSubmit} />
      default:
        return <DefaultFilter config={config} onSubmit={handleSubmit} />
    }
  }

  return (
    <Dialog aria-label={config.title + ' filter'} state={dialogState}>
      <DialogHeader className="text-sm">{config.title}</DialogHeader>
      {getDialogComponent()}
    </Dialog>
  )
}
