import React from 'react'

import { Select } from '../../../main/components'
import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Label } from '../../../main/components/typography'
import { FilterProps } from './types'

const ARRAY_DELIMITER = '|'
const OPTIONS = [
  { value: 'any', label: 'Any' },
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]

const PRESETS_MAP: { [key: string]: string } = {
  'option|short': 'optionshort',
  'option|notshort': 'optionnotshort',
  'notoption|short': 'notoptionshort',
  'notoption|notshort': 'notoptionnotshort',
}

export default function OptionsFilter({ onSubmit, config }: FilterProps) {
  const [optionable, setOptionable] = React.useState(OPTIONS[0].value)
  const [shortable, setShortable] = React.useState(OPTIONS[0].value)
  const [shortsalerestricted, setShortSaleRestricted] = React.useState(OPTIONS[0].value)
  const [halted, setHalted] = React.useState(OPTIONS[0].value)

  const setValues = React.useCallback((item: string) => {
    const getYesNo = (value: string) => (value.includes('not') ? 'no' : 'yes')
    switch (item) {
      case 'option':
      case 'notoption':
        setOptionable(getYesNo(item))
        break
      case 'short':
      case 'notshort':
        setShortable(getYesNo(item))
        break
      case 'shortsalerestricted':
      case 'notshortsalerestricted':
        setShortSaleRestricted(getYesNo(item))
        break
      case 'halted':
      case 'nothalted':
        setHalted(getYesNo(item))
        break
      case 'optionshort':
        setOptionable('yes')
        setShortable('yes')
        break
      case 'optionnotshort':
        setOptionable('yes')
        setShortable('no')
        break
      case 'notoptionshort':
        setOptionable('no')
        setShortable('yes')
        break
      case 'notoptionnotshort':
        setOptionable('no')
        setShortable('no')
        break
    }
  }, [])

  React.useEffect(() => {
    config.selectedValues.forEach((item) => {
      setValues(item)
    })
  }, [config, setValues])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const str = [
      { option: optionable },
      { short: shortable },
      { shortsalerestricted: shortsalerestricted },
      { halted: halted },
    ]
      .filter((item) => Object.values(item).every((value) => value !== 'any'))
      .map((item) => Object.entries(item).map(([key, value]) => `${value === 'yes' ? '' : 'not'}${key}`))
      .join(ARRAY_DELIMITER)

    onSubmit([PRESETS_MAP[str] ?? str])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <form id="js-smaFilerForm" onSubmit={handleSubmit}>
          <div className="flex">
            <div className="grid flex-1 grid-cols-2 gap-3">
              <Label title="Optionable">
                <Select
                  aria-label="Optionable"
                  items={OPTIONS}
                  value={optionable}
                  onChange={(item) => {
                    setOptionable(item.value)
                  }}
                />
              </Label>
              <Label title="Shortable">
                <Select
                  aria-label="Shortable"
                  items={OPTIONS}
                  value={shortable}
                  onChange={(item) => {
                    setShortable(item.value)
                  }}
                />
              </Label>
              <Label title="Short Sale Restricted">
                <Select
                  aria-label="Short Sale Restricted"
                  items={OPTIONS}
                  value={shortsalerestricted}
                  onChange={(item) => {
                    setShortSaleRestricted(item.value)
                  }}
                />
              </Label>
              <Label title="Halted">
                <Select
                  aria-label="Halted"
                  items={OPTIONS}
                  value={halted}
                  onChange={(item) => {
                    setHalted(item.value)
                  }}
                />
              </Label>
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-smaFilerForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
