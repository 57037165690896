import React from 'react'

import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { FilterProps } from './types'

const WEEK_MONTH_DELIMITER = 'x'
const DEFAULT_RANGE = { from: '', to: '' }

export default function VolatilityFilter({ config, onSubmit }: FilterProps) {
  const [weekRange, setWeekRange] = React.useState(DEFAULT_RANGE)
  const [monthRange, setMonthRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const parts = filter.split(WEEK_MONTH_DELIMITER)

      if (parts.length === 2) {
        const [wFrom = '', wTo = ''] = parts[0].includes('to') ? parts[0].split('to') : []
        const [mFrom = '', mTo = ''] = parts[1].includes('to') ? parts[1].split('to') : []

        setWeekRange({ from: wFrom, to: wTo })
        setMonthRange({ from: mFrom, to: mTo })
      }
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { from: wMin, to: wMax } = weekRange
    const { from: mMin, to: mMax } = monthRange
    const weekRangeStr = wMin || wMax ? `${wMin}to${wMax}` : null
    const monthRangeStr = mMin || mMax ? `${mMin}to${mMax}` : null

    let volatilityFilter = ''
    if (weekRangeStr) volatilityFilter += weekRangeStr
    if (weekRangeStr || monthRangeStr) volatilityFilter += WEEK_MONTH_DELIMITER
    if (monthRangeStr) volatilityFilter += monthRangeStr

    onSubmit([volatilityFilter])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <form id="js-volatilityFilterForm" onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 grid-rows-2 gap-3">
            <div className="relative flex flex-1 items-center pl-4">
              <p>Week</p>
            </div>
            <div className="relative flex flex-1">
              <Input
                label="Over"
                type="number"
                value={weekRange.from}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setWeekRange({ ...weekRange, from: e.currentTarget.value })
                }}
              />
            </div>
            <div className="relative flex flex-1">
              <Input
                label="Under"
                type="number"
                value={weekRange.to}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setWeekRange({ ...weekRange, to: e.currentTarget.value })
                }}
              />
            </div>

            <div className="relative flex flex-1 items-center pl-4">
              <p>Month</p>
            </div>
            <div className="relative flex flex-1">
              <Input
                label="Over"
                type="number"
                value={monthRange.from}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setMonthRange({ ...monthRange, from: e.currentTarget.value })
                }}
              />
            </div>
            <div className="relative flex flex-1">
              <Input
                label="Under"
                type="number"
                value={monthRange.to}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setMonthRange({ ...monthRange, to: e.currentTarget.value })
                }}
              />
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-volatilityFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
