import React from 'react'

import { eventListenerRegister } from './utils'

function HideCustom(this: HTMLElement) {
  const filter = this.getAttribute('data-filter')

  const el1 = document.getElementById('fsr_' + filter)
  const el2 = document.getElementById('fsm_' + filter)
  el1 && (el1.style.display = 'none')
  el2 && (el2.style.display = 'none')

  const select = document.getElementById('fs_' + filter) as HTMLSelectElement
  select.value = ''
  select.style.display = 'block'
  select.onchange?.(new Event('change'))

  return false
}

export function useHideCustomControll() {
  React.useEffect(() => {
    const ranges = Array.from(document.getElementsByClassName('js-hide-custom-range')) as HTMLElement[]
    const unregisterEventFuncs = [] as Array<() => void>
    for (let i = 0; i < ranges.length; i++) {
      const unregister = eventListenerRegister(ranges[i], 'click', HideCustom)
      unregisterEventFuncs.push(unregister)
    }

    const hides = Array.from(document.getElementsByClassName('js-custom-modal-hide')) as HTMLElement[]
    for (let i = 0; i < hides.length; i++) {
      const unregister = eventListenerRegister(hides[i], 'click', HideCustom)
      unregisterEventFuncs.push(unregister)
    }

    return () => {
      unregisterEventFuncs.forEach((unregister) => {
        unregister()
      })
    }
  }, [])

  return null
}
