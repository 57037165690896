import React from 'react'

import { Select } from '../../../main/components'
import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DELIMITER = '-'
const DEFAULT_RANGE = { from: '', to: '' }
const OPTIONS = [
  { value: 'a', label: 'Above' },
  { value: 'b', label: 'Below' },
]

export default function SMAFilter({ onSubmit, config }: FilterProps) {
  const [option, setOption] = React.useState(OPTIONS[0].value)
  const [range, setRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const [filterRange, filterOption] = filter.split(DELIMITER)

      if (!OPTIONS.some(({ value }) => value === filterOption)) {
        return
      }
      setOption(filterOption)

      if (filterRange) {
        const [from, to] = filterRange.split('to')
        setRange({ from, to })
      }
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([rangesToFilter([range], [`${DELIMITER}${option}`])])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <form id="js-smaFilerForm" onSubmit={handleSubmit}>
          <div className="flex">
            <div className="grid flex-1 grid-cols-3 gap-3">
              <Input
                label="From (%)"
                type="number"
                step={0.1}
                max={Number.MAX_SAFE_INTEGER}
                value={range.from}
                onChange={(e) => {
                  setRange({ ...range, from: e.currentTarget.value })
                }}
              />
              <Input
                label="To (%)"
                type="number"
                step={0.1}
                max={Number.MAX_SAFE_INTEGER}
                value={range.to}
                onChange={(e) => {
                  setRange({ ...range, to: e.currentTarget.value })
                }}
              />
              <div className="flex items-end">
                <Select
                  aria-label="Above/Below Select"
                  className="w-full"
                  items={OPTIONS}
                  value={option}
                  onChange={(item) => {
                    setOption(item.value)
                  }}
                />
              </div>
            </div>
            <div className="mx-4 flex items-end justify-center">
              <p className="leading-7">{config.filter.substring(3).toUpperCase()}</p>
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-smaFilerForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
