import React from 'react'

import { Button } from '../../../main/components/button'
import { Checkbox } from '../../../main/components/checkbox'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Heading } from '../../../main/components/typography'
import { formatDateToStringUS } from '../../shared/utils'
import { DateRange } from '../date-range'
import { dateRangeToFilterString, getInitialDateFromSelectedValues, getIsOptionDateRange } from '../utils'
import { FilterProps } from './types'

export default function DateRangeFilter({ config, onSubmit }: FilterProps) {
  const [state, setState] = React.useState<string[]>([])
  const [dateRange, setDateRange] = React.useState<{ from?: string; to?: string }>({})

  React.useEffect(() => {
    const initialDate = getInitialDateFromSelectedValues(config.selectedValues)

    if (initialDate) {
      setDateRange({ from: initialDate[0], to: initialDate[1] })
    }
    setState(config.selectedValues)
  }, [config])

  const handleSelectAll = () => {
    setState(config.options.map(({ value }) => value))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const dateRangeStr = dateRangeToFilterString(dateRange)
    const filters = [...state.filter((value) => !getIsOptionDateRange(value)), dateRangeStr]

    onSubmit(filters)
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <Heading level={5} className="mb-3 mt-0">
          Match any of the following:
        </Heading>
        <form id="js-daterangeFilterForm" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-1">
            {config.options.map(({ label, value }) => {
              const isChecked = state.includes(value)
              return (
                <div className="flex-1" key={value}>
                  <Checkbox
                    name={value}
                    checked={isChecked}
                    onChange={() =>
                      setState(isChecked ? state.filter((selectedValue) => value !== selectedValue) : [...state, value])
                    }
                  >
                    {label}
                  </Checkbox>
                </div>
              )
            })}
          </div>
          <div className="mt-2 flex flex-col">
            <Heading level={5} className="my-3">
              Custom date range:
            </Heading>
            <DateRange
              from={dateRange?.from && formatDateToStringUS(new Date(dateRange.from))}
              to={dateRange?.to && formatDateToStringUS(new Date(dateRange.to))}
              onChangeFrom={(from) => {
                setDateRange({ ...dateRange, from })
              }}
              onChangeTo={(to) => {
                setDateRange({ ...dateRange, to })
              }}
            />
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button className="mr-auto" onClick={handleSelectAll}>
          Select all
        </Button>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-daterangeFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
