import { getSanitizedTicker } from 'finviz-charts/app/utils'
import React from 'react'

import { eventListenerRegister } from './utils'

function ScreenerTickersInputOnChange(this: HTMLInputElement) {
  const inputValue = this.value,
    filter = this.getAttribute('data-filter'),
    query = this.getAttribute('data-url'),
    queryWithFilter = this.getAttribute('data-url-selected')!,
    regexp = new RegExp(filter + '_SELECTED-FILTER')
  let q = query

  if (inputValue !== '') {
    const sanitizedTickers = getSanitizedTicker(inputValue, true)

    if (sanitizedTickers === '') {
      alert('Separate multiple tickers by a comma, e.g.: SPY, QQQ, DIA.')
      return
    }

    const value = filter + '_' + sanitizedTickers.replaceAll(',', '|')
    q = queryWithFilter.replace(regexp, encodeURIComponent(value))
  }

  window.location.href = 'screener.ashx?' + q
}

export function useTickersInputController() {
  React.useEffect(() => {
    const inputs = Array.from(document.querySelectorAll('.js-tickers-input')) as HTMLElement[]
    const unregisterEventFuncs = [] as Array<() => void>
    for (let i = 0; i < inputs.length; i++) {
      const unregister = eventListenerRegister(inputs[i], 'change', ScreenerTickersInputOnChange)
      unregisterEventFuncs.push(unregister)
    }

    return () => {
      unregisterEventFuncs.forEach((unregister) => {
        unregister()
      })
    }
  }, [])

  return null
}
