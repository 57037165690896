import React from 'react'

import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { Heading } from '../../../main/components/typography'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DELIMITER = 'x'
const DEFAULT_RANGE = { from: '', to: '' }

export default function EarningsAndRevenueFilter({ config, onSubmit }: FilterProps) {
  const [earningsRange, setEarningsRange] = React.useState(DEFAULT_RANGE)
  const [revenueRange, setRevenueRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const parts = filter.split(DELIMITER)

      if (parts.length === 2) {
        const [eFrom = '', eTo = ''] = parts[0].includes('to') ? parts[0].split('to') : []
        const [rFrom = '', rTo = ''] = parts[1].includes('to') ? parts[1].split('to') : []

        setEarningsRange({ from: eFrom, to: eTo })
        setRevenueRange({ from: rFrom, to: rTo })
      }
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([rangesToFilter([earningsRange, revenueRange])])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <Heading level={5} className="mb-3 mt-0">
          Match all of the following:
        </Heading>
        <form id="js-earningsRevenueFilterForm" onSubmit={handleSubmit}>
          <div className="flex">
            <div className="mr-4 flex min-w-25 items-end justify-center">
              <p className="w-full whitespace-nowrap leading-7">EPS Surprise</p>
            </div>
            <div className="grid grow grid-cols-2 gap-3">
              <Input
                label="From (%)"
                type="number"
                value={earningsRange.from}
                step={0.1}
                max={Number.MAX_SAFE_INTEGER}
                onChange={(e) => {
                  setEarningsRange({ ...earningsRange, from: e.currentTarget.value })
                }}
              />
              <Input
                label="To (%)"
                type="number"
                value={earningsRange.to}
                step={0.1}
                max={Number.MAX_SAFE_INTEGER}
                onChange={(e) => {
                  setEarningsRange({ ...earningsRange, to: e.currentTarget.value })
                }}
              />
            </div>
          </div>

          <div className="mt-3 flex">
            <div className="mr-4 flex min-w-25 items-end justify-center">
              <p className="whitespace-nowrap leading-7">Revenue Surprise</p>
            </div>
            <div className="grid grow grid-cols-2 gap-3">
              <Input
                label="From (%)"
                type="number"
                value={revenueRange.from}
                step={0.1}
                onChange={(e) => {
                  setRevenueRange({ ...revenueRange, from: e.currentTarget.value })
                }}
              />
              <Input
                label="To (%)"
                type="number"
                value={revenueRange.to}
                step={0.1}
                onChange={(e) => {
                  setRevenueRange({ ...revenueRange, to: e.currentTarget.value })
                }}
              />
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-earningsRevenueFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
