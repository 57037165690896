import React from 'react'

import { Button } from '../../main/components/button'
import { DatePicker } from '../../main/components/date-picker'
import { Icon } from '../../main/components/icon'
import { Input } from '../../main/components/input'
import { formatDateToStringUS } from '../shared/utils'

interface DateRangeProps {
  from?: string
  to?: string
  onChangeFrom: (from: string | undefined) => void
  onChangeTo: (to: string | undefined) => void
}

const MIN_DATE = new Date('01/01/1900')

export function DateRange({ from, to, onChangeFrom, onChangeTo }: DateRangeProps) {
  return (
    <div className="date-range-body grid grid-cols-2 gap-3">
      <div className="date-picker-input-wrapper relative flex flex-1">
        <DatePicker
          minDate={MIN_DATE}
          value={from ? new Date(from) : undefined}
          onChange={(newFrom: Date) => {
            onChangeFrom(formatDateToStringUS(newFrom))
          }}
        >
          <Input
            type="input"
            readOnly
            label="From"
            rightContent={
              from ? (
                <Button
                  className="ml-auto mr-0"
                  theme="opacity"
                  appearance="square"
                  onClick={() => {
                    onChangeFrom(undefined)
                  }}
                >
                  <Icon name="close" width={18} />
                </Button>
              ) : undefined
            }
            value={from ?? ''}
          />
        </DatePicker>
      </div>
      <div className="date-picker-input-wrapper relative flex flex-1">
        <DatePicker
          minDate={MIN_DATE}
          value={to ? new Date(to) : undefined}
          onChange={(newTo: Date) => {
            onChangeTo(formatDateToStringUS(newTo))
          }}
        >
          <Input
            type="input"
            readOnly
            label="To"
            rightContent={
              to ? (
                <Button
                  className="ml-auto mr-0"
                  theme="opacity"
                  appearance="square"
                  onClick={() => {
                    onChangeTo(undefined)
                  }}
                >
                  <Icon name="close" width={18} />
                </Button>
              ) : undefined
            }
            value={to ?? ''}
          />
        </DatePicker>
      </div>
    </div>
  )
}
