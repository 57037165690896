import React from 'react'

import { Select } from '../../../main/components'
import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { Heading } from '../../../main/components/typography'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DELIMITER = 'x'
const SETTINGS_DELIMITER = '-'
const DEFAULT_RANGE = { from: '', to: '' }
const PRICE_OPTIONS = [
  { value: 'a', label: 'Above' },
  { value: 'b', label: 'Below' },
]
const EXTREMA_OPTIONS = [
  { value: 'h', label: 'High' },
  { value: 'l', label: 'Low' },
]

const parseData = (filterValue: string): [{ from: string; to: string }, string, string] | undefined => {
  if (filterValue.indexOf(SETTINGS_DELIMITER) > 0) {
    const rangeAndOptions = filterValue.split(SETTINGS_DELIMITER)
    if (
      rangeAndOptions[1].length === 2 &&
      PRICE_OPTIONS.some(({ value }) => value === rangeAndOptions[1][0]) &&
      EXTREMA_OPTIONS.some(({ value }) => value === rangeAndOptions[1][1])
    ) {
      const [from = '', to = ''] = rangeAndOptions[0].includes('to') ? rangeAndOptions[0].split('to') : []

      return [{ from, to }, rangeAndOptions[1][0], rangeAndOptions[1][1]]
    }
  }
}

export default function HighLowFilter({ config, onSubmit }: FilterProps) {
  const [price1, setPrice1] = React.useState(PRICE_OPTIONS[1].value)
  const [price2, setPrice2] = React.useState(PRICE_OPTIONS[0].value)
  const [extreme1, setExtreme1] = React.useState(EXTREMA_OPTIONS[0].value)
  const [extreme2, setExtreme2] = React.useState(EXTREMA_OPTIONS[1].value)
  const [range1, setRange1] = React.useState(DEFAULT_RANGE)
  const [range2, setRange2] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const parts = filter.split(DELIMITER)
      if (parts.length !== 2) {
        return
      }

      // Parse 1. part containing range, price and extreme
      const parsedData1 = parseData(parts[0])
      if (parsedData1 !== undefined) {
        const [{ from, to }, price, extreme] = parsedData1
        setRange1({ from, to })
        setPrice1(price)
        setExtreme1(extreme)
      }

      // Parse 2. part containing range, price and extreme
      const parsedData2 = parseData(parts[1])
      if (parsedData2 !== undefined) {
        const [{ from, to }, price, extreme] = parsedData2
        setRange2({ from, to })
        setPrice2(price)
        setExtreme2(extreme)
      }
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([
      rangesToFilter(
        [range1, range2],
        [`${SETTINGS_DELIMITER}${price1}${extreme1}`, `${SETTINGS_DELIMITER}${price2}${extreme2}`]
      ),
    ])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <Heading level={5} className="mb-3 mt-0">
          Match any of the following:
        </Heading>
        <form id="js-highLowFilterForm" onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 grid-rows-2 gap-3">
            <Input
              label="From (%)"
              type="number"
              value={range1.from}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.1}
              onChange={(e) => {
                setRange1({ ...range1, from: e.currentTarget.value })
              }}
            />
            <Input
              label="To (%)"
              type="number"
              value={range1.to}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.1}
              onChange={(e) => {
                setRange1({ ...range1, to: e.currentTarget.value })
              }}
            />
            <div className="flex items-end">
              <Select
                aria-label="Select Above/Below"
                className="w-full"
                items={PRICE_OPTIONS}
                value={price1}
                onChange={(item) => {
                  setPrice1(item.value)
                }}
              />
            </div>
            <div className="flex items-end">
              <Select
                aria-label="Select High/Low"
                className="w-full"
                items={EXTREMA_OPTIONS}
                value={extreme1}
                onChange={(item) => {
                  setExtreme1(item.value)
                }}
              />
            </div>
            <Input
              label="From (%)"
              type="number"
              value={range2.from}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.1}
              onChange={(e) => {
                setRange2({ ...range2, from: e.currentTarget.value })
              }}
            />
            <Input
              label="To (%)"
              type="number"
              value={range2.to}
              min={0}
              max={Number.MAX_SAFE_INTEGER}
              step={0.1}
              onChange={(e) => {
                setRange2({ ...range2, to: e.currentTarget.value })
              }}
            />
            <div className="flex items-end">
              <Select
                aria-label="Select Above/Below"
                className="w-full"
                items={PRICE_OPTIONS}
                value={price2}
                onChange={(item) => {
                  setPrice2(item.value)
                }}
              />
            </div>
            <div className="flex items-end">
              <Select
                aria-label="Select High/Low"
                className="w-full"
                items={EXTREMA_OPTIONS}
                value={extreme2}
                onChange={(item) => {
                  setExtreme2(item.value)
                }}
              />
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-highLowFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
