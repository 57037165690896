import React from 'react'

import { Button } from '../../../main/components/button'
import { Checkbox } from '../../../main/components/checkbox'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Heading } from '../../../main/components/typography'
import { getIsOptionDateRange } from '../utils'
import { FilterProps } from './types'

export default function DefaultFilter({ config, onSubmit }: FilterProps) {
  const [state, setState] = React.useState<string[]>([])

  React.useEffect(() => {
    setState(config.selectedValues)
  }, [config])

  const handleSelectAll = () => {
    setState(config.options.map(({ value }) => value).filter((item) => item !== 'stocksonly'))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const filters = [...state.filter((value) => !getIsOptionDateRange(value))]

    onSubmit(filters)
  }

  return (
    <>
      <DialogBody className="relative w-145 max-w-full">
        <Heading level={5} className="mb-3 mt-0">
          Match any of the following:
        </Heading>
        <form id="js-defaultFilterForm" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-1">
            {config.options.map(({ label, value }) => {
              const isChecked = state.includes(value)
              return (
                <div className="flex-1 self-center" key={value}>
                  <Checkbox
                    name={value}
                    className="leading-tight"
                    checked={isChecked}
                    onChange={() =>
                      setState(isChecked ? state.filter((selectedValue) => value !== selectedValue) : [...state, value])
                    }
                  >
                    {label}
                  </Checkbox>
                </div>
              )
            })}
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button className="mr-auto" onClick={handleSelectAll}>
          Select all
        </Button>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-defaultFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
