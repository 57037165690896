import React from 'react'

import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { formatDateToStringUS } from '../../shared/utils'
import { DateRange } from '../date-range'
import { dateRangeToFilterString, getInitialDateFromSelectedValues } from '../utils'
import { FilterProps } from './types'

export default function IpoDateFilter({ config, onSubmit }: FilterProps) {
  const [dateRange, setDateRange] = React.useState<{ from?: string; to?: string }>({})

  React.useEffect(() => {
    const initialDate = getInitialDateFromSelectedValues(config.selectedValues)
    if (initialDate) {
      setDateRange({ from: initialDate[0], to: initialDate[1] })
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const dateRangeStr = dateRangeToFilterString(dateRange)

    onSubmit([dateRangeStr])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <form id="js-ipodateFilterForm" onSubmit={handleSubmit}>
          <DateRange
            from={dateRange?.from && formatDateToStringUS(new Date(dateRange.from))}
            to={dateRange?.to && formatDateToStringUS(new Date(dateRange.to))}
            onChangeFrom={(from) => {
              setDateRange({ ...dateRange, from })
            }}
            onChangeTo={(to) => {
              setDateRange({ ...dateRange, to })
            }}
          />
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-ipodateFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
