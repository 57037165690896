import React from 'react'

import { eventListenerRegister } from './utils'

// Don't break on number with plus in front (+5 -> 5)
function ScreenerRemovePlus(value: string) {
  if (value && value[0] === '+') {
    return value.slice(1)
  }
  return value
}

function ScreenerValidateInputs(type: string, input1: HTMLInputElement, input2: HTMLInputElement) {
  const numberRegexp = type === 'range' ? /^([-+]?[0-9]+)$/ : /^([-+]?[0-9]*\.?[0-9]+)$/
  const isMatchInput1 = numberRegexp.test(input1.value)
  const isMatchInput2 = numberRegexp.test(input2.value)

  if (input1.value !== '' && !isMatchInput1) {
    alert('Only ' + (type === 'range' ? 'whole ' : '') + 'numbers are allowed.')
    input1.focus()
    return false
  }
  if (input2.value !== '' && !isMatchInput2) {
    alert('Only ' + (type === 'range' ? 'whole ' : '') + 'numbers are allowed.')
    input2.focus()
    return false
  }

  if (isMatchInput1 && Math.abs(Number(input1.value)) > Number.MAX_SAFE_INTEGER) {
    alert('Number is too large.')
    input1.focus()
    return false
  }
  if (isMatchInput2 && Math.abs(Number(input2.value)) > Number.MAX_SAFE_INTEGER) {
    alert('Number is too large.')
    input2.focus()
    return false
  }

  return true
}

function ScreenerCustomRangeOnChange(this: HTMLElement) {
  const select = document.getElementById('fs_' + (this as HTMLElement).getAttribute('data-filter')) as HTMLInputElement,
    type = select.value,
    inputs = Array.from(this.parentNode!.parentNode!.parentNode!.querySelectorAll('input')),
    filter = select.getAttribute('data-filter'),
    query = select.getAttribute('data-url'),
    queryWithFilter = select.getAttribute('data-url-selected')!,
    regexp = new RegExp(filter + '_selected_filter')
  let q = query

  if (inputs[0].value !== '' || inputs[1].value !== '') {
    if (!ScreenerValidateInputs(type, inputs[0], inputs[1])) {
      return
    }
    const value = filter + '_' + ScreenerRemovePlus(inputs[0].value) + 'to' + ScreenerRemovePlus(inputs[1].value)
    q = queryWithFilter.replace(regexp, encodeURIComponent(value))
  }

  window.location.href = 'screener.ashx?' + q
}

export function useCustomRangeController() {
  React.useEffect(() => {
    const inputs = Array.from(document.querySelectorAll('.js-custom-range input')) as HTMLElement[]
    const unregisterEventFuncs = [] as Array<() => void>
    for (let i = 0; i < inputs.length; i++) {
      const unregister = eventListenerRegister(inputs[i], 'change', ScreenerCustomRangeOnChange)
      unregisterEventFuncs.push(unregister)
    }

    return () => {
      unregisterEventFuncs.forEach((unregister) => {
        unregister()
      })
    }
  }, [])

  return null
}
