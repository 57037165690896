import React from 'react'

import { Table, TableBody } from '../../../main/components/Table'
import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { Heading } from '../../../main/components/typography'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DELIMITER = 'x'
const DEFAULT_RANGE = { from: '', to: '' }

export default function CurrentVolumeFilter({ config, onSubmit }: FilterProps) {
  const [sharesRange, setSharesRange] = React.useState(DEFAULT_RANGE)
  const [usdRange, setUsdRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const parts = filter.split(DELIMITER)
      const [sharesRangeFrom = '', sharesRangeTo = ''] = parts[0].includes('to') ? parts[0].split('to') : []
      setSharesRange({ from: sharesRangeFrom, to: sharesRangeTo })

      if (parts.length === 2) {
        const [usdRangeFrom = '', usdRangeTo = ''] = parts[1].includes('to') ? parts[1].split('to') : []
        setUsdRange({
          from: usdRangeFrom && (Number(usdRangeFrom) / 1000).toFixed(2).replace('.00', ''),
          to: usdRangeTo && (Number(usdRangeTo) / 1000).toFixed(2).replace('.00', ''),
        })
      }
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([
      rangesToFilter([
        sharesRange,
        {
          from: usdRange.from && (Number(usdRange.from) * 1000).toFixed(2).replace('.00', ''),
          to: usdRange.to && (Number(usdRange.to) * 1000).toFixed(2).replace('.00', ''),
        },
      ]),
    ])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <Heading level={5} className="mb-3 mt-0">
          Match all of the following:
        </Heading>
        <form id="js-currentVolumeFilterForm" onSubmit={handleSubmit}>
          <Table className="max-w-md">
            <TableBody>
              <tr>
                <td className="w-0 whitespace-nowrap pr-2">Shares</td>
                <td>
                  <Input
                    type="number"
                    value={sharesRange.from}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    step={0.01}
                    onChange={(e) => {
                      setSharesRange({ ...sharesRange, from: e.currentTarget.value })
                    }}
                  />
                </td>
                <td className="px-2">-</td>
                <td>
                  <Input
                    type="number"
                    value={sharesRange.to}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    step={0.01}
                    onChange={(e) => {
                      setSharesRange({ ...sharesRange, to: e.currentTarget.value })
                    }}
                  />
                </td>
                <td className="px-1">K</td>
              </tr>
              <tr>
                <td className="p-2"></td>
              </tr>
              <tr>
                <td className="w-0 whitespace-nowrap pr-2">USD volume</td>
                <td>
                  <Input
                    type="number"
                    value={usdRange.from}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    step={0.01}
                    onChange={(e) => {
                      setUsdRange({ ...usdRange, from: e.currentTarget.value })
                    }}
                  />
                </td>
                <td className="px-2">-</td>
                <td>
                  <Input
                    type="number"
                    value={usdRange.to}
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    step={0.01}
                    onChange={(e) => {
                      setUsdRange({ ...usdRange, to: e.currentTarget.value })
                    }}
                  />
                </td>
                <td className="px-1">M</td>
              </tr>
            </TableBody>
          </Table>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-currentVolumeFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
