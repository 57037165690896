import React from 'react'

import { Button } from '../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../main/components/dialog'
import { Input } from '../../../main/components/input'
import { Heading } from '../../../main/components/typography'
import { rangesToFilter } from '../utils'
import { FilterProps } from './types'

const DELIMITER = 'x'
const DEFAULT_RANGE = { from: '', to: '' }

export default function TargetPriceFilter({ config, onSubmit }: FilterProps) {
  const [aboveRange, setAboveRange] = React.useState(DEFAULT_RANGE)
  const [belowRange, setBelowRange] = React.useState(DEFAULT_RANGE)

  React.useEffect(() => {
    const filter = config.selectedValues[0]
    if (filter) {
      const parts = filter.split(DELIMITER)

      if (parts.length === 2) {
        const [aFrom = '', aTo = ''] = parts[0].includes('to') ? parts[0].split('to') : []
        const [bFrom = '', bTo = ''] = parts[1].includes('to') ? parts[1].split('to') : []

        setAboveRange({ from: aFrom, to: aTo })
        setBelowRange({ from: bFrom, to: bTo })
      }
    }
  }, [config])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit([rangesToFilter([aboveRange, belowRange])])
  }

  return (
    <>
      <DialogBody className="relative flex w-145 max-w-full flex-col justify-center">
        <Heading level={5} className="mb-3 mt-0">
          Match any of the following:
        </Heading>
        <form id="js-targetPriceFilterForm" onSubmit={handleSubmit}>
          <div className="flex">
            <div className="grid grow grid-cols-2 gap-3">
              <Input
                label="From (%)"
                type="number"
                value={aboveRange.from}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setAboveRange({ ...aboveRange, from: e.currentTarget.value })
                }}
              />
              <Input
                label="To (%)"
                type="number"
                value={aboveRange.to}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setAboveRange({ ...aboveRange, to: e.currentTarget.value })
                }}
              />
            </div>
            <div className="mx-4 flex items-end justify-center">
              <p className="whitespace-nowrap leading-7">Above Price</p>
            </div>
          </div>

          <div className="mt-3 flex">
            <div className="grid grow grid-cols-2 gap-3">
              <Input
                label="From (%)"
                type="number"
                value={belowRange.from}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setBelowRange({ ...belowRange, from: e.currentTarget.value })
                }}
              />
              <Input
                label="To (%)"
                type="number"
                value={belowRange.to}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                step={0.1}
                onChange={(e) => {
                  setBelowRange({ ...belowRange, to: e.currentTarget.value })
                }}
              />
            </div>
            <div className="mx-4 flex items-end justify-center">
              <p className="whitespace-nowrap leading-7">Below Price</p>
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button as={DialogDismiss}>Cancel</Button>
        <Button type="submit" form="js-targetPriceFilterForm" theme="blue">
          Submit
        </Button>
      </DialogFooter>
    </>
  )
}
