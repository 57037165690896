import React from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  CustomDateRange,
  ITimeframeGroup,
  TimeframeBar,
  generatePathForTimeframeDateRange,
  getDateRangeGroupsWithHref,
  getDefaultDateRangeAnchorDate,
  getTimeframeFromDateRange,
  getTimeframeGroups,
  useCustomDateRangeDialog,
  useFavoriteTimeframes,
} from '../../main/components/timeframe-bar'
import { TIMEFRAME } from '../../main/constants'
import { Instrument } from '../../main/types'

function getSelectedTimeframeAndDateRange(urlQuery: { p?: string; dr?: string }) {
  const selectedTimeframe = (urlQuery.p ?? FinvizSettings.screenerCurrentTimeframe?.timeframe) as TIMEFRAME
  let selectedDateRange = urlQuery.dr
  if (!selectedDateRange && !urlQuery.p) {
    selectedDateRange = FinvizSettings.screenerCurrentTimeframe?.dateRange || undefined
  }

  return { selectedTimeframe, selectedDateRange }
}

export function ScreenerTimeframeBar() {
  const isPremium = FinvizSettings.hasUserPremium
  const [searchParams] = useSearchParams()
  const { favoriteTimeframes, toggleFavoriteTimeframe } = useFavoriteTimeframes(isPremium)
  const currentQuery = React.useMemo(
    () => Object.fromEntries(searchParams) as { p?: string; dr?: string },
    [searchParams]
  )
  const containerRef = React.useRef<HTMLElement>(document.getElementById('screener-fullview-links')!)
  const { selectedTimeframe, selectedDateRange } = getSelectedTimeframeAndDateRange(currentQuery)

  React.useEffect(() => {
    containerRef.current.style.visibility = 'unset'
  }, [])

  const timeFrameGroupsWithHref = getTimeframeGroups(isPremium).map((timeFrameGroup: ITimeframeGroup) => ({
    label: timeFrameGroup.label,
    timeframes: timeFrameGroup.timeframes.map((timeFrame) => ({
      ...timeFrame,
      href: generatePathForTimeframeDateRange({
        isPremium,
        pathname: window.location.pathname,
        timeframe: timeFrame.value,
        utmCampaign: 'screener-charts-intraday',
        dateRangeQueryKey: 'dr',
      }),
      withReload: true,
    })),
  }))

  const dateRangeGroupsWithHref = getDateRangeGroupsWithHref({
    isPremium: FinvizSettings.hasUserPremium,
    customRange: selectedDateRange,
    isCustomAvailable: true,
    withReload: true,
    instrument: Instrument.Stock,
    generatePath: (dateRange) =>
      generatePathForTimeframeDateRange({
        isPremium,
        pathname: window.location.pathname,
        timeframe: dateRange.timeframe!,
        dateRange: dateRange.value,
        utmCampaign: 'screener-charts-daterange',
        dateRangeQueryKey: 'dr',
      }),
  })
  const { onDialogSubmit, customDateRangeDialogState, handleDateRangeSelect } = useCustomDateRangeDialog({
    instrument: Instrument.Stock,
    anchorDate: getDefaultDateRangeAnchorDate(),
    shouldReloadOnNavigate: true,
    pathGenerator: ({ dateRange, timeframe }) => ({
      pathname: window.location.pathname,
      search: new URLSearchParams({ ...currentQuery, p: timeframe, dr: dateRange ?? '' }).toString(),
    }),
  })

  return (
    <>
      <TimeframeBar
        className="space-x-2 text-gray-900 dark:text-gray-50"
        size="small"
        rounding={FinvizSettings.hasRedesignEnabled ? 'regular' : 'none'}
        timeFrameGroups={timeFrameGroupsWithHref}
        dateRangeGroups={dateRangeGroupsWithHref}
        selectedTimeframe={selectedTimeframe}
        selectedDateRange={selectedDateRange ?? null}
        favoriteTimeframes={favoriteTimeframes}
        onTimeframeSelect={() => {}}
        onDateRangeSelect={handleDateRangeSelect}
        onFavoriteTimeframeToggle={({ value }) => {
          toggleFavoriteTimeframe(value as TIMEFRAME)
        }}
        compactViewMaxBreakpointPx={0}
        isFavoritesEnabled={FinvizSettings.hasUserPremium}
        isTimeframeSelectEnabled={true}
        onCustomDateRangeClick={() => {
          if (isPremium) customDateRangeDialogState.show()
        }}
      />
      <CustomDateRange
        dialogState={customDateRangeDialogState}
        dateRange={selectedDateRange}
        anchorDate={getDefaultDateRangeAnchorDate()}
        onDialogSubmit={({ dateRange }: { dateRange: string | null }) => {
          const value = dateRange ?? ''
          const timeframe = getTimeframeFromDateRange({
            dateRange: value,
            instrument: Instrument.Stock,
            anchorDate: getDefaultDateRangeAnchorDate(),
            isPremium: isPremium,
          })
          return onDialogSubmit({ value, timeframe: timeframe })
        }}
      />
    </>
  )
}
